import React from "react";

import Layout from "../../components/Layout";
import Students from "../../epics/students/Students";

const StudentsPage = () => (
  <Layout>
    {/*<Students />*/}
  </Layout>
);

export default StudentsPage;
